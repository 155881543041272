import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button,
    IconButton,
    Drawer
  } from "@material-ui/core";
  import MenuIcon from "@material-ui/icons/Menu";
  import { createTheme, ThemeProvider } from '@material-ui/core/styles';

  import React, { useState, useEffect } from "react";
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  const useStyles = makeStyles(() => ({
    header: {
      backgroundColor: "#9147FF",
      paddingRight: "79px",
      paddingLeft: "118px",
      "@media (max-width: 900px)": {
        paddingLeft: 0,
      },
    },
    logo: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 600,
      color: "#FFFEFE",
      textAlign: "left",
    },
    menuButton: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 700,
      size: "18px",
      marginLeft: "38px",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    drawerContainer: {
      padding: "20px 30px",
    },
  }));
  
  export default function Header(props) {
    const { header, menuButton, toolbar, drawerContainer } = useStyles();

    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
    });
  
    const { mobileView, drawerOpen } = state;
    
    const onClickLogout = () => {
        window.location = "https://srvaux01.progamerid.com/api/v2/rivals/logout";
    }

    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener("resize", () => setResponsiveness());
  
      return () => {
        window.removeEventListener("resize", () => setResponsiveness());
      };
    }, []);
  
    const displayDesktop = () => {
      return (
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <div style={{marginRight: "4rem"}}>{getMenuButtons()}</div>
            {props.user != null && 
                <div style={{display: "flex"}}>
                    <img src={props.user.profile_image_url} alt="profile" style={{width: "40px", marginRight: ".5rem", borderRadius: "9000px"}} />
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Typography variant="body1" component="div" style={{fontWeight: "bold"}}>
                            {props.user.display_name}
                        </Typography>
                        {props.user.broadcaster_type !== null && props.user.broadcaster_type != "" &&
                          <Typography variant="body2" component="div" style={{color: "rgba(255, 255, 255, 0.64)"}}>
                              {props.user.broadcaster_type}
                          </Typography>
                        }
                        
                    </div>
                    
                </div>
                
            }
        </Toolbar>
      );
    };
  
    const displayMobile = () => {
      const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
      const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
  
      return (
        <Toolbar>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
  
          <Drawer
            {...{
              anchor: "left",
              open: drawerOpen,
              onClose: handleDrawerClose
            }}
          >
            <div className={drawerContainer}>{getDrawerChoices()}</div>
          </Drawer>
  
          <div>{femmecubatorLogo}</div>
        </Toolbar>
      );
    };
  
    const getDrawerChoices = () => {
        return (
            <div>
                <Button onClick={onClickLogout}>Log Out</Button>
            </div>
        );
    };
  
    const femmecubatorLogo = (
        <div style={{display: "flex"}}>
            <b style={{fontSize: "27px", fontWeight: "bold"}}>GL Panel</b>
        </div>
    );
  
    const getMenuButtons = () => {
        return (
            <div>
                <Button 
                    {...{
                        key: "LogOut",
                        color: "inherit",
                        onClick: onClickLogout,
                        className: menuButton,
                    }}
                >Log Out</Button>
            </div>
        );
    };
  
    return (
        <ThemeProvider theme={darkTheme}>
            <header>
                <AppBar className={header}>
                    {mobileView ? displayMobile() : displayDesktop()}
                </AppBar>
            </header>
        </ThemeProvider>
    );
  }