import '../App.css';

function Login() {
    return (
        <div className='loginColumn'>
          <div className="row">
            <div>
              <div className="ibox-content" style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                <p class="m-t"></p>
                <h2>Sign into Replay Uploader</h2>
                <p></p>
                <hr />
                
                <p class="m-t">
                  <a class="btn btn-primary block full-width m-b btn-twitch" style={{textDecoration: "none"}} href="https://srvaux01.progamerid.com/api/v2/rivals/auth/twitch">
                    <svg
                      data-name="Layer 1"
                      viewBox="0 0 48 53.04"
                      style={{
                        width: 14,
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M44 24.63l-8 7.57h-8l-7 6.63V32.2h-9V3.79h32zM10 0L0 9.47v34.1h12V53l10-9.47h8l18-17V0zm28 10.72h-4v11.36h4zm-15-.05h4V22h-4z"
                        fill="#fff"
                        fillRule="evenodd"
                      />
                    </svg>
                    &nbsp;Login with Twitch</a>
                </p>
              </div>
            </div>
          </div>
        </div>
    );
}
  
export default Login;