import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';

import axios from 'axios';
import '../App.css';
import Header from './Header';
import DragAndDrop from './DragAndDrop';

function Home() {
    const [user, setUser] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileProgress, setFileProgress] = useState(0.0);
    const [file, setFile] = useState("");

    const onHandleDrop = (files) => {
        if(files.length > 1) {
            toast.error('Please drop a single file.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        uploadFile(files[0]);
    }

    const uploadFile = async(file) => {
        if(file.type !== "" || !file.name.includes(".replay")) {
            toast.error('Please upload a fortnite replay file.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        
        setUploading(true);
        setFile(file.name);

        const id = toast.loading("Uploading replay...");

        const responseGame = await axios.get("https://srvaux01.progamerid.com/api/v2/rivals/actualGame", {withCredentials: true});

        Object.defineProperty(file, 'name', {
            writable: true,
            value: "rivals/Game"+responseGame.data.number+"_"+user.login+".replay"
        });

        const requestObject = {
            method:'POST' ,
            body: JSON.stringify({
                fileName: file.name,
                fileType: file.type,
                bucketName: "bucket-reply-fortnite"
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type':'application/json'
            },
            credentials: "include"
        }
        
        const documentStyles = document.documentElement.style;
        documentStyles.setProperty('--progress', `0% 100%`);

        fetch('https://srvaux01.progamerid.com/api/v2/rivals/uploadReplay', requestObject)
            .then(res => res.json()).then(response => {

                axios.request({
                    method: "PUT", 
                    url: response.url, 
                    data: file, 
                    onUploadProgress: (progressEvent) => {
                        setFileProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        documentStyles.setProperty('--progress', `${(Math.round((progressEvent.loaded * 100) / progressEvent.total)).toFixed(0)}% 100%`);
                    }
                }).then(data => {
                    toast.update(id, { render: "The replay was uploaded successfully!", type: "success", isLoading: false, autoClose: 4000 });
                    setUploading(false);
                    setFileProgress(100.0);
                    documentStyles.setProperty('--progress', `100% 100%`);
                    setTimeout(() => {
                        window.location = "https://report.progamerid.com/dashboard";
                    }, 4000);
                });
            })
    }

    useEffect(() => {
        async function getData() {
            const response = await axios.get("https://srvaux01.progamerid.com/api/v2/rivals/", {withCredentials: true});

            if(response.data.id == null) 
                window.location = "https://report.progamerid.com/login";
            else
                setUser(response.data);
        }

        getData();
    }, []);

    if(!user) {
        return <></>;
    }

    return (
        <>
            <Header user={user} />
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8rem"}}>
                <div className="upload">
                    <div className="upload-files">
                        <header>
                            <p>
                                <FontAwesomeIcon icon={faCloudUploadAlt} style={{width: "32px", height: "32px", marginRight: "10px"}} />
                                <span className="up">up</span>
                                <span className="load">load</span>
                            </p>
                        </header>
                        <DragAndDrop className={"body " + (file !== "" ? "hidden" : "")} id="drop" handleDrop={onHandleDrop}>
                            <FontAwesomeIcon icon={faFileAlt} className={"pointer-none"} style={{fontSize: "65px",color: "lightgray"}} />
                            <label htmlFor="btn-upload"><p className="pointer-none"><b>Drop</b> your replay here <br /> or <a style={{cursor: "pointer"}} id="triggerFile">browse</a> in your PC.</p>
                            <input type="file" id="btn-upload" onChange={e => uploadFile(e.target.files[0])} /></label>
                        </DragAndDrop>
                        <footer className={file !== "" ? "hasFiles" : ""}>
                            <div className="divider">
                                <span>FILES</span>
                            </div>
                            <div className="list-files">
                                {file !== "" && 
                                    <div className="file file--1">
                                        <div className="name"><span>{file}</span></div>
                                        <div className={"progress " + (uploading ? "active" : "")} style={{}}></div>
                                        <div className={"done" + (!uploading ? "anim" : "")}>
                                            {!uploading && file !== "" && 
                                                <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 70 70">
                                                    <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                                                    <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="transparent"/>
                                                    <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <button className={"importar " + (!uploading && file !== "" ? "active" : "")} onClick={e => {
                                setFile("");
                                setUploading(false);
                                setFileProgress(0.0);
                            }}>UPLOAD MORE FILES</button>
                        </footer>
                    </div>
                </div>
            </div>
        </>
    );
}
  
export default Home;
  